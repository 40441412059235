@import "src/styles/mixin";

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  padding: 8px 16px 8px 16px;
  border: 1px solid var(--grey-lines-color);
  border-radius: 32px;
  max-width: 191px;
  width: 191px;

  .name {
    @include font14;
    font-weight: 600;
    white-space: nowrap;
  }
}