@import "/src/styles/mixin";

.swiper-stories-popup {
  width: 100%;
  padding: 0 0 !important;

  .swiper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0;

    &-wrapper {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding-bottom: 24px;
      height: auto;
      width: auto;
    }
  }
}