.mobile_actions {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 20px 16px;
  width: 100%;
  display: none;
  justify-content: right;
  gap: 6px;
  z-index: 1000;
  @media screen and (max-width: 767px) {
    display: flex;
  }

  .button {
    background-color: rgba(255, 255, 255, 0.3);
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    backdrop-filter: blur(20px);
    svg {
      path {
        background-color: var(--secondary-color);
      }
    }
  }

}

.not_active {
  display: none;
}