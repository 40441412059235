@import "/src/styles/mixin";

.stories_item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  transition: all 0.25s;
  cursor: pointer;
  text-align: center;
  aspect-ratio: 1;

  .image_container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 162px;
    height: 132px;
    width: 132px;
    border-radius: 100%;
    border: 2px solid var(--primary-color);
    //overflow: hidden;
    aspect-ratio: 1;

    .image {
      height: 120px;
      width: 120px;
      aspect-ratio: 1/1;
      transition: .5s ease;
      border-radius: 100%;
      user-select: none;

      @media screen and (max-width: 767px) {
        height: 80px;
        width: 80px;
      }
    }

    @media screen and (max-width: 767px) {
      min-height: 92px;
      width: 92px;
      aspect-ratio: 1/1;
      padding: 2px;
      max-height: 92px;
    }
  }


  .stories_text {
    @include font16;
    line-height: 16px;
    margin-top: 8px;
    max-width: 132px;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 32px;

    @media screen and (max-width: 1023px) {
      line-height: 16px;
    }

    @media screen and (max-width: 767px) {
      @include font12;
      max-width: 72px;
      line-height: 16px;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 767px) {
    width: 80px;
    height: 130px;
    min-height: 150px;
  }

  &.is_checked {
    .image_container {
      border-color: var(--blue-background-color);
      aspect-ratio: 1;
    }
  }

  &:hover {
    .image_container {
      .image {
        transform: scale(1.05);
      }
    }
  }
}