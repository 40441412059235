.swiper-stories--main {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 30px;
  overflow-y: visible;

  @media screen and (max-width: 767px) {
    padding: 0 16px;
  }

  .swiper-wrapper {
    display: flex;
    height: 100%;

    .swiper-slide-active {
      transition: translate 1s ease;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .stories_slide {
    max-width: 92px !important;

    @media screen and (max-width: 767px) {
      max-width: 80px !important;
    }
  }
}