.ranges {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 4px;
  padding: 22px 16px;

  .not_active {
    width: 100%;
    position: relative;
    height: auto;
    display: flex;
    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      height: 4px;
      border-radius: 20px;
      background-color: var(--grey-lines-color);
      z-index: 6;
    }
  }

  .range {
    width: 100%;
    position: relative;
    height: auto;
    display: flex;

    .range_active {
      position: absolute;
      width: 0%;
      top: 0px;
      left: 0px;
      right: 0px;
      animation-name: range;
      height: 4px;
      border-radius: 20px;
      background-color: var(--primary-color);
      z-index: 7;
    }

    .range_active::after {
      content: attr(data-duration);
      font-size: 20px;
      color: greenyellow;
    }

    @keyframes range {
      0% {
        width: 0;
      }

      100% {
        width: 100%;
      }

    }

    .range_main {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      height: 4px;
      border-radius: 20px;
      background-color: var(--grey-lines-color);
      z-index: 6;
    }
  }
}