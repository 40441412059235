.wrap {
  // background-color: rgba(37, 37, 37, .5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  position: fixed;
  height: 100dvh;
  width: 100svw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow: hidden;

  .content {
    height: 100%;
    width: 100%;

    .swiper-slide-active {
      height: 100%;
      width: 100%;
    }
  }
}