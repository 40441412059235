@import "src/styles/mixin";

.story {
  position: relative;
  width: 320px;
  height: 568px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  border-radius: var(--border-radius-small);

  @media screen and (max-width: 767px) {
    width: 100svw;
    height: 100dvh;
    border-radius: 0;
  }

  .image {
    position: absolute;
    width: 320px;
    height: 568px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: .25s !important;

    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  .video {
    width: 100%;
    height: auto;
  }

  &_info {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 24px;
    left: 50%;
    width: calc(100% - 32px);
    height: auto;
    transform: translate(-50%, 0);
    gap: 8px;

    .title {
      @include font20;
      line-height: 42px;
      font-weight: 700;
      text-align: left;
      color: var(--white-color);
    }

    .subtitle {
      @include font14;
      font-weight: 700;
      text-align: left;
      color: var(--white-color);
    }

    .link {
      @include contentButton;
      white-space: nowrap;
      width: 100%;
      padding: 12px 20px;
      text-overflow: ellipsis;
      user-select: auto;
    }
  }


}

.story_not__active {
  position: relative;
  width: 320px;
  height: 568px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-small);

  @media screen and (max-width: 767px) {
    width: 100svw;
    height: 100dvh;
    border-radius: 0;
  }

  .image {
    position: absolute;
    width: 320px;
    height: 568px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(2px);

    @media screen and (max-width: 767px) {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

  }

  p {
    font-size: 32px;
    line-height: 42px;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--white-color);
  }
}