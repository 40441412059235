@import "src/styles/mixin";

.focused {
  position: relative;
  width: 320px;
  height: 568px;
  background-color: var(--black-color);
  background-size: cover;
  cursor: grab;
  z-index: 10 !important;
  border-radius: var(--border-radius-small);
  user-select: none;

  .wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .buttons {
        display: flex;
        align-items: center;
        position: absolute;
        left: -16px;
        right: -16px;
        bottom: 50%;
        justify-content: space-between;
        transform: translate(0, 50%);
        z-index: 12;

        @media screen and (max-width: 767px) {
          justify-content: center;
          width: 100%;
          left: 0;
          right: 0;
        }

        .next_slide {
          position: relative;
          background-color: var(--white-color);
          min-height: 32px;
          min-width: 32px;
          max-width: 32px;
          max-height: 32px;
          padding: 14px 12px 2px 8px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: .25s;
          cursor: pointer;

          @media screen and (max-width: 767px) {
            display: none;
          }

          &::before {
            content: '';
            transform: translateY(-50%) rotate(45deg);
            width: 10px;
            height: 10px;
            border: 2px solid var(--primary-color);
            border-width: 2px 2px 0 0;
          }

          &:hover {
            background-color: var(--blue-background-color);
          }
        }

        .prev_slide {
          position: relative;
          background-color: var(--white-color);
          min-height: 32px;
          min-width: 32px;
          max-width: 32px;
          max-height: 32px;
          padding: 16px 8px 4px 12px;
          min-width: 32px;
          min-height: 32px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: .25s;
          cursor: pointer;

          @media screen and (max-width: 767px) {
            display: none;
          }

          &::before {
            content: '';
            transform: translateY(-50%) rotate(225deg);
            width: 10px;
            height: 10px;
            border: 2px solid var(--primary-color);
            border-width: 2px 2px 0 0;
          }

          &:hover {
            background-color: var(--blue-background-color);
          }
        }

        .loader {
          width: 32px;
          height: 32px;
          border: 2px solid;
          border-color: transparent var(--primary-color) var(--primary-color);
          border-radius: 100%;
          animation: loaderRotating .75s linear infinite;
        }

        @keyframes loaderRotating {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }


    }

    .prev_stories {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 30%;
      display: none;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }

    .next_stories {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 30%;
      display: none;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }
  }

  @media screen and (max-width: 767px) {
    width: 100svw;
    height: 100dvh;
    border-radius: 0;
  }
}

.item {
  display: flex;
  width: 320px;
  height: 568px;
  cursor: grab;
  border-radius: var(--border-radius-small);
  background-color: var(--black-color);
  z-index: 11;
  overflow: hidden;

  .wrap {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    transition: .25s;

    .ranges {
      display: none;
    }

    .content {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .buttons {
        display: none;
      }
    }


  }

  @media screen and (max-width: 767px) {
    width: 100svw;
    height: 100dvh;
    border-radius: 0;
  }
}